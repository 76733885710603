const state = {
  lastPath: '',
  currentPath: '',
  currentRouteName: ''
}
const mutations = {
  UPDATE_CURRENTPATH (state, path) {
    state.currentPath = path
  },
  UPDATE_LASTPATH (state, path) {
    state.lastPath = path
  },
  UPDATE_ROUTE_NAME (state, name) {
    state.currentRouteName = name
  }
}

export default {
  state,
  mutations
}
