const state = {
  term: '',
  sum: 0,
  paperList: [],
  page: 0,
  yearCounts: {},
  selectedList: [],
  idlist: {},
  showPage: false
}
const mutations = {
  UPDATE_PAPER_CACHE (state, {term, sum, paperList, page, yearCounts, selectedList, idlist, showPage}) {
    state.term = term
    state.sum = sum
    state.paperList = paperList
    state.page = page
    state.yearCounts = yearCounts
    state.selectedList = selectedList
    state.idlist = idlist
    state.showPage = showPage
  }
}

export default {
  state,
  mutations
}
