<template>
  <div class="top_header">
    <div class="page-box">
      <div class="top_header-logo">
        <a href="/"><img src="/static/logo/yxy-logo-green.png" alt=""/></a>
      </div>
      <div class="header-menus">
        <router-link class="header-menu search" :to="{name: 'paperSearch'}" :class="{active: currentRouteName==='paperSearch'}">PubMed 文献检索</router-link>
        <!-- <router-link class="header-menu" :to="{name: 'paperSearchAdvance'}" :class="{active: currentRouteName==='paperSearchAdvance'}">高级检索</router-link> -->
        <router-link class="header-menu sciscore" :to="{name: 'if'}" :class="{active: currentRouteName==='if'}">SCI影响因子查询</router-link>
        <router-link class="header-menu nsfcfund" :to="{name: 'fund'}" :class="{active: currentRouteName==='fund'}">NSFC基金查询</router-link>
        <router-link class="header-menu ppmanage" :to="{name: 'manage'}" :class="{active: currentRouteName==='manage'}">在线文献管理</router-link>
        <router-link class="header-menu scihub" :to="{name: 'scihub'}" :class="{active: currentRouteName==='scihub'}">SCI-HUB地址</router-link>
        <router-link class="header-menu" :to="{name: 'fullhelp'}" :class="{active: currentRouteName==='fullhelp'}">文献全文互助</router-link>
        <template v-if="$nodeEnv==='development' || $nodeEnv==='production_test'">
          <router-link class="header-menu prep" :to="{name: 'email'}" :class="{active: currentRouteName==='email'}">邮箱验证</router-link>
          <router-link class="header-menu satistic" :to="{name: 'about'}" :class="{active: currentRouteName==='about'}">用户访问统计</router-link>
        </template>
      </div>
      <div style="float:right;">
        <template v-if="loggedIn">
          <a class="header-menu user">
            <img v-if="headimgurl" :src="headimgurl" alt="" class="head">
            <img v-else src="../../assets/imgs/login-user.png">
            <ul class="submenu" @click.stop="()=>{}">
              <li>昵称：{{username}}</li>
              <li>操作：<a class="logout" @click.stop="handleLogout">退出</a></li>
            </ul>
          </a>
        </template>
        <template v-else>
          <a class="header-menu" @click="handleLogin">登录</a>
        </template>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import Domain from '../scihub/domain'
import Login from '../../utils/login'
import { getStorageItem, updateStorageItem } from '../../utils/cache'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  mixins: [Domain, Login],
  data () {
    return {
      //
    }
  },
  computed: {
    loggedIn () {
      let auth = getStorageItem('auth')
      let loggedAuth = this.$store.state.auth.loggedAuth
      let isLogin = this.$store.getters.getLoginState
      return auth && loggedAuth && isLogin
    },
    username () {
      let auth = getStorageItem('auth')
      let username = this.$store.state.auth.username
      return (auth && auth.nickname) || username
    },
    headimgurl () {
      let auth = getStorageItem('auth')
      let headurl = this.$store.state.auth.headimgurl
      return (auth && auth.headimgurl) || headurl
    },
    currentRouteName () {
      return this.$store.getters.getCurrentRouteName
    }
  },
  mounted () {
    this.loadCurrentDomain()
  },
  methods: {
    handleLogout () {
      this.logout()
    },
    handleLogin () {
      if (this.currentRouteName === 'paper') {
        updateStorageItem('route', {name: 'paper', params: {'id': this.$router.currentRoute.params.id}})
      } else if (this.currentRouteName === 'paperSearch') {
        let term = ''
        let $children = this.$parent.$children
        if ($children && $children.length) {
          $children.forEach(child => {
            if (child.$options.name === 'paper-search') {
              term = child.term
            }
          })
        }
        updateStorageItem('route', {name: 'paperSearch', query: {'term': term}})
      } else {
        updateStorageItem('route', {name: this.currentRouteName})
      }
      this.login()
    },
    logout () {
      this.$http.post(`${this.httpRoot}/auth/logout`, {}, authenticate())
      .then(res => res.json())
      .then(res => {
        // 请求发出后再退出
        this.$store.dispatch('logout')
        //
        if (res.status === 'success') {
          if (res.token) {
            updateStorageItem('token', res.token)
          }
          if (res.token) {
            this.$router.push({name: 'home'})
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="less">
.top_header {
  // background: #112e51;
  background: #064069;
  position: relative;
  height: 36px;
  line-height: 36px;
  .top_header-logo {
    height: 32px;
    float: left;
    margin-top: 2px;
    margin-right: 2.25rem;
    img {
      display: block;
      height: 100%;
    }
  }
  .clear {
    clear:both;
  }
}
.clearfix {
  &:before, &:after {
    display: table;
    content: ""
  }
  &:after {
    clear: both;
  }
}
.header-menus {
  display: inline-block;
}
.header-menu {
  padding: 0 1rem;
  text-align: center;
  color: #f2f4f5;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .12);
  font-size: 0.8125rem;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  display: inline-block;
  height: 36px;
  cursor: pointer;
  position: relative;
  transition: all .2s ease-in-out;
  &:hover {
    background: #085992;
    color: #fff;
    transition: all .2s ease-in-out;
  }
  &.active {
    height: 35px;
    background-color: #085992;
    // border-bottom: 2px solid #007eff;
  }
  &.user {
    padding-left: 5px;
    padding-right: 5px;
    &:hover {
      &> ul {
        display: block;
        // box-shadow: 0 0 5px rgba(0, 0, 0, .4);
        background: #085992;
        color: #fff;
      }
    }
    img.head {
      height: 80%;
      padding: 10%;
      border-radius: 50%;
    }
    img {
      height: 100%;
    }
  }
}
.submenu {
  position: absolute;
  top: 36px;
  left: 0;
  display: none;
  list-style: none;
  background: #fff;
  color: #333;
  &> li {
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    a.logout {
      // color: #230353;
      text-decoration: underline;
    }
  }
}
@media screen and (max-width: 450px) {
  .header-menu {
    &.search {
      display: none;
    }
    &.sciscore {
      display: none;
    }
    &.nsfcfund {
      display: none;
    }
    &.ppmanage {
      display: none;
    }
    &.scihub {
      display: none;
    }
    &.prep {
      display: none;
    }
    &.satistic {
      display: none;
    }
  }
}
@media screen and (max-width: 600px) {
  .header-menu {
    &.sciscore {
      display: none;
    }
    &.nsfcfund {
      display: none;
    }
    &.ppmanage {
      display: none;
    }
    &.scihub {
      display: none;
    }
    &.prep {
      display: none;
    }
    &.satistic {
      display: none;
    }
  }
}
@media screen and (max-width: 750px) {
  .header-menu {
    &.sciscore {
      display: none;
    }
    &.nsfcfund {
      display: none;
    }
    &.ppmanage {
      display: none;
    }
    &.prep {
      display: none;
    }
    &.satistic {
      display: none;
    }
  }
}
@media screen and (max-width: 850px) {
  .header-menu {
    &.nsfcfund {
      display: none;
    }
    &.ppmanage {
      display: none;
    }
    &.prep {
      display: none;
    }
    &.satistic {
      display: none;
    }
  }
}
@media screen and (max-width: 950px) {
  .header-menu {
    &.ppmanage {
      display: none;
    }
    &.prep {
      display: none;
    }
    &.satistic {
      display: none;
    }
  }
}
@media screen and (max-width: 1050px) {
  .header-menu {
    &.prep {
      display: none;
    }
    &.satistic {
      display: none;
    }
  }
}
</style>
