import { login as loginCache, logout as logoutCache } from '../utils/auth'
// vuex actions
export function updateLastPath ({commit}, path) {
  commit('UPDATE_LASTPATH', path)
}

export function updateCurrentPath ({commit}, path) {
  commit('UPDATE_CURRENTPATH', path)
}

export function updateCurrentRouteName ({commit}, name) {
  commit('UPDATE_ROUTE_NAME', name)
}

export function login ({commit}, token) {
  loginCache(token)
  commit('LOGIN')
}

export function logout ({commit}) {
  logoutCache()
  commit('LOGOUT')
}

export function updateWinHeight ({commit}, height) {
  commit('UPDATE_WIN_HEIGHT', height)
}

export function updateWinWidth ({commit}, width) {
  commit('UPDATE_WIN_WIDTH', width)
}

export function setUser ({commit}, {name, headurl}) {
  commit('UPDATE_USER', {name, headurl})
}

export function updatePaperCache ({commit}, data) {
  commit('UPDATE_PAPER_CACHE', data)
}
