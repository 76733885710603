// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import polyfill from './utils/polyfill'

Vue.use(VueRouter)
Vue.use(VueResource)
polyfill()

// 'http://localhost:8080'
// 'https://www.iyxy.cn:50060'
const httpRoot = ip + ':' + port

if (DOCKER === 'docker') {
  Vue.prototype.httpRoot = $httpRoot
} else {
  Vue.prototype.httpRoot = httpRoot
}
if (esearch === 'test') {
  Vue.prototype.$nodeEnv = 'production_test'
} else {
  Vue.prototype.$nodeEnv = process.env.NODE_ENV
}
Vue.prototype.backurl = backurl
Vue.prototype.appid = appid
Vue.config.errorHandler = function (err, vm, info) {
  console.log('errorHandler', err, vm, info)
}

Vue.http.headers.common['Authorization'] = 'Basic YXBpOnBhc3N3b3Jk'
Vue.http.headers.common['Accept'] = 'application/json'
Vue.http.headers.common['Content-Type'] = 'application/json; charset=utf-8'

import routes from './router/routes'
import deviceMixin, { androidInputBugFix } from './utils/device'

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

androidInputBugFix()
deviceMixin(Vue)

import store from './store'
import updateRoute from './store/updateRoute'

router.beforeEach((to, from, next) => {
  // if (to.path !== '/login' && !store.getters.getLoginState) {
  //   updateRoute(store, from, to)
  //   router.push({name: 'login', query: {next: to.path}})
  // } else {
  updateRoute(store, from, to)
  next()
  // }
})

window.addEventListener('resize', function () {
  var winHeight = document.documentElement.clientHeight
  var winWidth = document.documentElement.clientWidth
  store.dispatch('updateWinHeight', winHeight)
  store.dispatch('updateWinWidth', winWidth)
}, false)

window.$upgrade = false
import App from './App'

/* eslint-disable no-new */
const app = new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

