<template>
  <div class="upgrade-note" @click="adCount">
    <!--<div class="upgrade-note__box">系统将于2022年11月12日（本周六）上午9:30开始升级，服务暂停约2个小时。升级完成后系统可正常使用!</div>-->
    <div class="upgrade-note__box"><a target="_blank" href="https://mp.weixin.qq.com/s/uXHsHJxN1rwadgBHkVLVEQ">银杏叶科研平台定于9月14日在上海举办《三维细胞培养体系搭建与干细胞相关课题应用》研讨会。</a></div>
  </div>
</template>

<script>
import { authenticate } from '../utils/auth'
export default {
  methods: {
    adCount () {
      this.$http.post(`${this.httpRoot}/auth/ad/click`, {}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          //
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style>
</style>
