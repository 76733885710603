const REGISTED = 'REGISTERED'
const LOGGED = 'LOGGED'
const FINISHED = 'FINISHED'
const VERIFIED = 'VERIFIED'

export {
  REGISTED,
  LOGGED,
  FINISHED,
  VERIFIED
}
