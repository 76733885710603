<template>
  <modal v-model="showModal" dimmer transparent title="微信扫码登录">
    <div class="login-url">
      <img :src="url" alt="">
    </div>
    <div class="text-tip">关注公众号</div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    url: String
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  }
}
</script>
<style>
.login-url {
  padding: 0 15px;
}
.login-url img {
  width: 200px;
  height: 200px;
}
.text-tip {
  text-align: center;
  color: rgb(245, 87, 87);
  padding-bottom: 5px;
}
</style>
