const state = {
  winHeight: window.innerHeight,
  winWidth: window.innerWidth
}
const mutations = {
  UPDATE_WIN_HEIGHT (state, height) {
    state.winHeight = height
  },
  UPDATE_WIN_WIDTH (state, width) {
    state.winWidth = width
  }
}

export default {
  state,
  mutations
}
