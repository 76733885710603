import Index from '../components/Index'

const NotFound = () => import('../components/NotFound')
const About = () => import('../components/About')
const Login = () => import('../components/login/login')
const Home = () => import('../components/home/home')

const Paper = () => import('../components/paper/paper')
const PaperSearch = () => import('../components/paper-search/paper-search')
const PaperSearchAdvance = () => import('../components/paper-search-advance/paper-search-advance')
const Manage = () => import('../components/manage/manage')
const Citations = () => import('../components/manage/citations')
const ImpactFactor = () => import('../components/journal/impact-factor')
const Fund = () => import('../components/fund/fund')
const Scihub = () => import('../components/scihub/scihub')
const FullHelp = () => import('../components/help/paper-help')
const HelpMe = () => import('../components/help/help-me')
const HelpIntro = () => import('../components/help/help-intro')
const HelpPrize = () => import('../components/help/help-prize')
const HelpHelper = () => import('../components/help/help-helper')
const HelpDetail = () => import('../components/help/help-detail')
const HelpPdf = () => import('../components/help/help-pdf')
const HelpPdf2 = () => import('../components/help/help-pdf2')
const HelpPdfMobile = () => import('../components/help/help-pdf-mobile')
const HelpEmail = () => import('../components/help/help-email')
const HelpMeHelp = () => import('../components/help/help-me-help')
const Email = () => import('../components/Email')
const Manual = () => import('../components/Manual')

export default [
  {
    path: '/',
    name: 'index',
    component: Index,
    children: [{
      path: '',
      name: 'home',
      component: Home
    }, {
      path: 'pubmed/:id',
      name: 'paper',
      component: Paper
    }, {
      path: 'pubmed',
      name: 'paperSearch',
      component: PaperSearch
    }, {
      path: 'advanced',
      name: 'paperSearchAdvance',
      component: PaperSearchAdvance
    }, {
      path: 'manage',
      name: 'manage',
      component: Manage
    }, {
      path: 'citations',
      name: 'citations',
      component: Citations
    }, {
      path: 'if',
      name: 'if',
      component: ImpactFactor
    }, {
      path: 'fund',
      name: 'fund',
      component: Fund
    }, {
      path: 'scihub',
      name: 'scihub',
      component: Scihub
    }, {
      path: 'fullhelp',
      name: 'fullhelp',
      component: FullHelp
    }, {
      path: 'helpme',
      name: 'helpme',
      component: HelpMe
    }, {
      path: 'helpintro',
      name: 'helpintro',
      component: HelpIntro
    }, {
      path: 'helpprize',
      name: 'helpprize',
      component: HelpPrize
    }, {
      path: 'helper',
      name: 'helper',
      component: HelpHelper
    }, {
      path: 'helpdetail/:id',
      name: 'helpdetail',
      component: HelpDetail
    }, {
      path: 'helppdf/:id',
      name: 'helppdf',
      component: HelpPdf
    }, {
      path: 'helppdf2/:id',
      name: 'helppdf2',
      component: HelpPdf2
    }, {
      path: 'helppdfmobile/:id',
      name: 'helppdfmobile',
      component: HelpPdfMobile
    }, {
      path: 'helpemail',
      name: 'helpemail',
      component: HelpEmail
    }, {
      path: 'myhelp',
      name: 'myhelp',
      component: HelpMeHelp
    }, {
      path: '/about',
      name: 'about',
      component: About
    }, {
      path: '/email',
      name: 'email',
      component: Email
    }, {
      path: '/manual',
      name: 'manual',
      component: Manual
    }]
  }, {
    path: '/login',
    name: 'login',
    component: Login
  }, {
    path: '*',
    component: NotFound
  }
]
