import { authenticate, catchExpired } from '../../utils/auth'
import { getStorageItem, updateStorageItem } from '../../utils/cache'

export default {
  methods: {
    loadCurrentDomain () {
      this.$http.get(`${this.httpRoot}/scihub/domain/detail`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.globalDomain = res.domain || ''
          let temp = getStorageItem('domain')
          if (!temp) {
            this.currentDomain = res.domain || ''
            this.updateLocalDomain(res.domain || '')
          } else {
            let found = false
            this.domainList.forEach(domain => {
              if (domain === temp) {
                found = true
                this.currentDomain = temp
              }
            })
            if (!found) {
              // 缓存的domain 已过期不在列表中了
              this.currentDomain = res.domain || ''
              this.updateLocalDomain(res.domain || '')
            }
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    updateLocalDomain (domain) {
      updateStorageItem('domain', domain)
    }
  }
}
