<template>
  <transition name="fade">
    <div class="cu-mask" :class="{'dimmer': dimmer, 'transparent': transparent}" v-show="visiable" @click="handleMaskClick">
      <div :class="[moveable?'cu-modal-move':'cu-modal']" @click="handleModalClick" :style="posStyle">
        <div class="cu-modal-close-wrap" @click="handleClose" v-if="!hideClose"><a class="cu-modal-close"></a></div>
        <div class="cu-modal-header" v-if="($slots.header || title)"
          @mousedown="handleHeadMouseDown"
          @mouseup="handleHeadMouseUp">
          <slot name="header">
            <div class="modal-header-inner">{{ title }}</div>
          </slot>
        </div>
        <div class="cu-modal-body">
          <slot></slot>
        </div>
        <div class="cu-modal-footer" v-if="!hideFooter">
          <slot name="footer">
            <button class="cancel" @click.stop="handleCancelClick">{{ cancelText }}</button>
            <button class="confirm" @click.stop="handleOkClick">{{ okText }}</button>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    dimmer: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: false
    },
    maskClose: {
      type: Boolean,
      default: false
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    okText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    moveable: {
      type: Boolean,
      default: false
    },
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 1500
    }
  },
  data () {
    return {
      visiable: this.value,
      posStyle: {},
      lastwinx: 0,
      lastwiny: 0,
      lastmx: 0,
      lastmy: 0
    }
  },
  watch: {
    value (val) {
      this.visiable = val
    },
    visiable (val) {
      if (!val) {
        //
      } else {
        if (this.autoClose) {
          setTimeout(() => {
            this.close()
          }, this.duration)
        }
      }
    },
    left (val) {
      if (val) {
        this.lastwinx = val
        this.posStyle = {
          left: this.lastwinx + 'px',
          top: this.lastwiny + 'px'
        }
      }
    },
    top (val) {
      if (val) {
        this.lastwiny = val
        this.posStyle = {
          left: this.lastwinx + 'px',
          top: this.lastwiny + 'px'
        }
      }
    }
  },
  mounted () {
    this.updateWinPos()
  },
  beforeDestroy () {
    // 再次清除mousemove事件，防止泄露
    this.$el.removeEventListener('mousemove', this.handleMouseMove)
  },
  methods: {
    handleClose () {
      this.close()
    },
    handleMaskClick (e) {
      // 再次清除mousemove事件
      this.$el.removeEventListener('mousemove', this.handleMouseMove)
      if (this.maskClose && e.target === this.$el) {
        this.close()
      }
    },
    handleModalClick (e) {
      // 冒泡到modal内的click事件，全部阻止继续冒泡
      e.stopPropagation()
    },
    handleOkClick () { this.$emit('on-ok') },
    handleCancelClick () { this.$emit('on-cancel') },
    handleHeadMouseDown (e) {
      // 在header处按下鼠标，给全屏mask添加mousemove事件处理函数
      if (!this.$el.querySelector('.cu-modal-move')) return
      let clientRect = this.$el.querySelector('.cu-modal-move').getBoundingClientRect()
      this.lastwinx = clientRect.left
      this.lastwiny = clientRect.top
      this.lastmx = e.clientX
      this.lastmy = e.clientY
      this.posStyle = {
        left: this.lastwinx + 'px',
        top: this.lastwiny + 'px'
      }
      this.$el.addEventListener('mousemove', this.handleMouseMove)
    },
    handleHeadMouseUp (e) {
      // 在header处松开鼠标，给全屏mask删除mousemove事件处理函数
      this.$el.removeEventListener('mousemove', this.handleMouseMove)
    },
    handleMouseMove (e) {
      // 在全屏mask上处理mousemove事件
      let mx = e.clientX
      let my = e.clientY
      let deltax = mx - this.lastmx
      let deltay = my - this.lastmy
      this.lastmx = mx
      this.lastmy = my
      this.lastwinx += deltax
      this.lastwiny += deltay
      this.posStyle = {
        left: this.lastwinx + 'px',
        top: this.lastwiny + 'px'
      }
    },
    close () {
      this.visiable = false
      this.$emit('input', false)
    },
    updateWinPos () {
      if (!this.$el.querySelector('.cu-modal-move')) return
      let clientRect = this.$el.querySelector('.cu-modal-move').getBoundingClientRect()
      this.lastwinx = this.$store.state.win.winWidth / 2 - clientRect.width / 2
      this.lastwiny = this.$store.state.win.winHeight / 2 - clientRect.height / 2
      this.posStyle = {
        left: this.lastwinx + 'px',
        top: this.lastwiny + 'px'
      }
    }
  }
}
</script>
<style lang="less">
.cu-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: #fff;
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0 0px 8px 0 #666;
}
.cu-modal-move {
  position: absolute;
  left: 40%;
  top: 40%;
  background: #fff;
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0 0 8px 0 #666;
}
.cu-modal-header {
  cursor: pointer;
  padding: 7px 7px 7px 15px;
  // border-bottom: 1px solid #ccc;
  font-size: 15px;
  font-weight: 500;
}
.cu-modal-body {
  padding: 0;
}
.cu-modal-close-wrap {
  position: absolute;
  top: 0;
  right: 0;
}
.cu-modal-close {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  color: #666;
  cursor: pointer;
  position: relative;
  &:after {
    position: absolute;
    content: ' ';
    width: 1px;
    height: 1.5rem;
    background-color: #666;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: background-color .2s;
  }
  &:before {
    background-color: #666;
    content: ' ';
    width: 1px;
    height: 1.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: background-color .2s;
  }
  &:hover {
    &:after, &:before {
      background-color: #222;
    }
  }
}
.cu-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 15px 0;
  button {
    cursor: pointer;
    padding: 3px 12px;
    margin-right: 15px;
    height: 18px;
    line-height: 18px;
    box-sizing: content-box;
    border: none;
    border-radius: 2px;
    outline: 0;
    &.confirm {
      background: #0071bc;
      color: #fff;
      &:active {
        background: #034a79;
      }
    }
    &.cancel {
      &:active {
        background: #e9e9e9;
      }
    }
  }
}
</style>
