import { loggedIn, loginAuth } from '../../utils/auth'

const state = {
  loggedIn: loggedIn(),
  loggedAuth: loginAuth(),
  username: '',
  headimgurl: ''
}
const mutations = {
  LOGIN (state) {
    state.loggedIn = true
  },
  LOGOUT (state) {
    state.loggedIn = false
    state.loggedAuth = false
  },
  UPDATE_USER (state, {name, headurl}) {
    state.loggedAuth = true
    state.username = name
    state.headimgurl = headurl
  }
}

export default {
  state,
  mutations
}
