<template>
  <div>
    <div :class="['yxy_header', {'note': isNote}]">
      <y-header></y-header>
    </div>
    <div :class="['yxy_container', {'note': isNote}]" :style="{height: bodyHeight + 'px'}">
      <router-view></router-view>
      <advertisement v-show="showAd && isNote"></advertisement>
    </div>
    <login-win :url="qrcodeUrl" :show="showQrcode" @on-hide="showQrcode=false"></login-win>
    <login-warn-win :show="showLoginWarn" @on-hide="showLoginWarn=false" :text="tipText"></login-warn-win>
    <advertisement-header v-show="isNote"></advertisement-header>
  </div>
</template>

<script>
import YHeader from './header/header.vue'
import LoginWin from './login/login-win.vue'
import LoginWarnWin from './login/login-warn-win.vue'
import Advertisement from './Advertisement.vue'
import AdvertisementHeader from './Advertisement-header.vue'
export default {
  name: 'Index',
  components: { YHeader, LoginWin, LoginWarnWin, Advertisement, AdvertisementHeader },
  computed: {
    bodyHeight () {
      return this.$store.getters.getWinHeight - 36 - (window.$upgrade ? 22 : 0)
    },
    showAd () {
      return this.$store.getters.getCurrentRouteName === 'paper' || this.$store.getters.getCurrentRouteName === 'paperSearch'
    }
  },
  data () {
    return {
      qrcodeUrl: '',
      showQrcode: false,
      showLoginWarn: false,
      tipText: '登录超时',
      isNote: window.$upgrade
    }
  }
}
</script>
<style lang="less">
.yxy_header {
  position:fixed;
  top:0;
  z-index:6;
  width:100%;
  height:36px;
  &.note {
    height: 48px;
    .top_header {
      margin-top: 22px;
    }
  }
}
@media screen and (max-width: 600px) {
  .yxy_container {
    overflow: scroll !important;
  }
}
.yxy_container {
  margin-top: 36px;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &.note {
    margin-top: 48px;
  }
}
.page-box {
  max-width: 1080px;
  margin: 0 auto;
}
.if-chart {
  position: fixed;
  transform: translate(-100%, -100%);
  top: 0;
  left: 0;
  z-index: 99;
  .chart-box {
    width:500px;
    height:200px;
    background:#f3f3f3;
    box-shadow: 0 0 10px rgba(0,0,0,0.45);
  }
}
.j-full-title {
  position: fixed;
  transform: translate(-100%, -100%);
  top: 0;
  left: 0;
  z-index: 99;
  .j-full-title-box {
    text-align: center;
    border-radius: 4px;
    padding: 0 40px;
    height:40px;
    line-height: 40px;
    background:#f3f3f3;
    box-shadow: 0 0 10px rgba(0,0,0,0.45);
  }
}
</style>
