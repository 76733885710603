<template>
  <div class="ad-panel">
    <div class="ad-content" @click="adCount">
      <a target="_blank" href="https://mp.weixin.qq.com/s/uXHsHJxN1rwadgBHkVLVEQ">银杏叶科研平台定于9月14日在上海举办《三维细胞培养体系搭建与干细胞相关课题应用》研讨会。</a>
    </div>
  </div>
</template>

<script>
import { authenticate } from '../utils/auth'
export default {
  methods: {
    adCount () {
      this.$http.post(`${this.httpRoot}/auth/ad/click`, {}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          //
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.ad-panel {
  position: fixed;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.ad-content {
  width: 65px;
  height: 250px;
  padding: 10px;
  background-color: #f2f7ff;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(50, 50, 50, 0.35);
}
.ad-content a {
  color: #5c1111;
}
</style>
