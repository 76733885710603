import { updateStorageItem } from './cache'
import { authenticate, catchExpired } from './auth'

export default {
  methods: {
    // 通过微信公众平台登录
    login () {
      let left = (this.$store.getters.getWinWidth - 600) / 2
      let url = this.backurl + '/login'
      let appid = this.appid
      window.open('https://open.weixin.qq.com/connect/qrconnect?appid=' + appid + '&redirect_uri=' + encodeURIComponent(url) + '&response_type=code&scope=snsapi_login&connect_redirect=1#wechat_redirect', 'login', `width=600,height=500,top=100,left=${left},scrollbars=0`)
    },
    // 通过关注公众号登录
    // login () {
    //   let nonce = new Date().getTime().toString()
    //   this.$http.post(`${this.httpRoot}/auth/ticket`, {sceneId: nonce}, authenticate())
    //   .then(res => res.json())
    //   .then(res => {
    //     if (res.status === 'success' && res.url) {
    //       let ticket = res.ticket
    //       this.$parent.qrcodeUrl = res.url
    //       this.$parent.showQrcode = true
    //       this.loginPoll(nonce, ticket, 0)
    //     }
    //   })
    //   .catch(err => {
    //     catchExpired(err, this)
    //   })
    // },
    loginPoll (nonce, ticket, times) {
      this.$http.post(`${this.httpRoot}/auth/login/poll`, {sceneId: nonce, ticket}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoginTipWin = false
          this.showLoginTipWin2 = false
          this.showConfirmWin = false
          this.$parent.showQrcode = false
          this.$parent.showLoginWarn = false
          // update user info
          if (res.auth && res.auth.nickname) {
            updateStorageItem('auth', res.auth)
            this.$store.dispatch('setUser', {name: res.auth.nickname, headurl: res.auth.headimgurl})
          }
          // update login state
          if (res.token) {
            this.$store.dispatch('login', res.token)
          }
          // if (res.token) {
          //   // 跳转到之前页面
          //   let route = getStorageItem('route')
          //   if (route) {
          //     this.$router.push(route)
          //   } else {
          //     this.$router.push({name: 'home'})
          //   }
          // }
        } else {
          if (times < 60) {
            setTimeout(() => {
              this.loginPoll(nonce, ticket, times + 1)
            }, 1000)
          } else {
            this.$parent.showQrcode = false
            this.$parent.showLoginWarn = true
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}