import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import win from './modules/win'
import route from './modules/route'
import auth from './modules/auth'
import paper from './modules/paper'

Vue.use(Vuex)

const store = new Vuex.Store({
  actions,
  getters,
  modules: {
    win,
    route,
    auth,
    paper
  }
})

export default store
