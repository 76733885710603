// vuex getters
export function getLoginState (state) {
  return state.auth.loggedIn
}

export function getWinHeight (state) {
  return state.win.winHeight
}

export function getWinWidth (state) {
  return state.win.winWidth
}

export function getCurrentPath (state) {
  return state.route.currentPath
}

export function getCurrentRouteName (state) {
  return state.route.currentRouteName
}

export function getLastPath (state) {
  return state.route.lastPath
}
